<template>
    
    <div ref="payment">
      <h3>{{ $t('hospitality.booking.info.methodOfPayment.title') }}: <span>{{ getPaymentTitle() }}</span></h3>

      <div class="row" v-if="methodOfPayment.type == 'PAY_LATER'">
        <div class="col">
          {{ getLocalizedText(methodOfPayment.note) }}
        </div>
      </div>
      <div class="table-responsive" v-else-if="methodOfPayment.type == 'CARD'">
        <table class="table">
            <tbody>
                <tr>
                    <td class="header">{{$t('shop.orders.headers.paymentStatus')}}</td>
                    <td class="col">
                      {{ getPaymentStatusLabel() }}
                    </td>
                </tr>
                <tr v-if="transaction.details">
                    <td class="header">Details</td>
                    <td class="col">
                        {{ transaction.details.type }} {{ transaction.details.number }}
                    </td>
                </tr>
            </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col"></div>
        <div class="col-lg-6 clearfix">
          <div class="table-responsive">
            <table class="table cart amounts">
              <tbody>
                <tr class="cart_item">
                  <td class="cart-product-name">
                    <strong>{{ $t("hospitality.booking.info.cart-total") }}</strong>
                  </td>

                  <td class="cart-product-name">
                    <span class="amount">{{ formatPriceAmount(getOrderProductsAmount()) }}</span>
                  </td>
                </tr>
                <tr class="cart_item" v-if="getOrderPromoCodesReduction() != null">
                    <td class="cart-product-name">
                        <strong>{{ $t("hospitality.booking.info.promoCodes-total") }}</strong>
                    </td>
                    <td class="cart-product-name">
                        <span class="amount">- {{ formatPriceAmount(getOrderPromoCodesReduction()) }} </span>
                    </td>
                </tr>
                <tr class="cart_item">
                  <td class="cart-product-name">
                    <span class="amount color lead"><strong>{{ $t("hospitality.booking.info.booking-total") }}</strong></span>
                  </td>

                  <td class="cart-product-name">
                    <span class="amount color lead"><strong>{{ formatPriceAmount(getOrderTotalAmount()) }}</strong></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
       
</template>

<style>

  .button.payment {
    width: 100%;
  }

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { utils } from '@fwk-client/modules/shop/stores/order';
import { containerUtils } from '@fwk-client/modules/shop/stores/index';
import * as hospitality from '@root/src/client/utils/hospitality';


import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDay, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';
import { diff } from '@igotweb-node-api-utils/date';
import { useAgencyAdmin } from '../../../../composables/useAgencyAdmin';
import { useUsers } from '../../../../../../composables/useUsers';

export default defineComponent({
    props: {
        booking: {
          type: Object as PropType<any>,
          required: true
        }
    },
    components: {
        
    },
    setup(props, context) {
        const app = getApp();
        const store = useStore();

        const { selectedSite } = useAgencyAdmin(props, context);
        const { getUserFromID } = useUsers({
            ...props,
            site: ref(selectedSite.value)
        }, context);

        const { booking } = toRefs(props);

        const transaction = computed(() => {
          if(booking.value) {
            return booking.value.transaction;
          }
        })

        const methodOfPayment = computed(() => {
          if(transaction.value) {
            return transaction.value.method
          }
          return null;
        })

        const getPaymentTitle = () => {
          return hospitality.getPaymentMethodLabel(app, booking.value);
        }

        const getPaymentStatusLabel = () => {
          return hospitality.getPaymentStatusLabel(app, booking.value);
        }
        
        const getOrderProductsAmount = () => {
          var productsAmount = containerUtils.getProductsAmount(booking.value);
          if(productsAmount && productsAmount != 0) {
            return  productsAmount;
          }
          return null;
        }

        const getOrderPromoCodesReduction = () => {
          var reduction =  containerUtils.getPromoCodesReduction(booking.value);
          return reduction;
        }

        const getOrderTotalAmount = () => {
          var totalAmount = utils.getOrderTotalAmount(booking.value);
          if(totalAmount && totalAmount != 0) {
            return  totalAmount;
          }
          return null;
        }

        return {
            transaction,
            booking,
            methodOfPayment,
            getPaymentTitle,
            getPaymentStatusLabel,
            getOrderProductsAmount,
            getOrderPromoCodesReduction,
            getOrderTotalAmount
        }

    }
})

</script>