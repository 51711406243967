<template>
  
    <form role="form" name="review-booking" class="review-booking">

      <CartRecap :booking="booking"></CartRecap>

      <hr/>

      <ContactRecap :booking="booking"></ContactRecap>

      <hr/>

      <PaymentRecap :booking="booking"></PaymentRecap>

      <hr/>

      <ExistingBookings v-if="booking.status == 'TO_VALIDATE'" :booking="booking"></ExistingBookings>

      <Validate v-if="booking.status == 'TO_VALIDATE'" :booking="booking" v-on:booking-updated="onItemUpdated"></Validate>

      <PaymentConfirm v-if="booking.status == 'PAYMENT'" :booking="booking" v-on:booking-updated="onItemUpdated"></PaymentConfirm>
      
    </form>

</template>

<style>

  .review-booking hr {
    border-top: 2px solid rgba(0,0,0,.3);
    margin-left: -20px;
    margin-right: -20px;
  }

  .review-booking td.header {
    white-space: nowrap;
  }

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import CartRecap from '../recap/CartRecap.vue';
import ContactRecap from '../recap/ContactRecap.vue';
import PaymentRecap from '../recap/PaymentRecap.vue';
import ExistingBookings from '../validate/ExistingBookings.vue';
import Validate from '../validate/Validate.vue';
import PaymentConfirm from '../payment/PaymentConfirm.vue';

export default defineComponent({
  props: {
      booking : {
        type: Object,
        required: true
      }
  },
  emits: ["booking-updated"],
  components: {
    CartRecap,
    ContactRecap,
    PaymentRecap,
    ExistingBookings,
    Validate,
    PaymentConfirm
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { booking } = toRefs(props);

    const onItemUpdated = (item:any) => {
      context.emit("booking-updated", item);
    }

    return {
      booking,
      onItemUpdated
    }
  }
})
</script>