<template>
    <div>
        <h3>{{ $t('hospitality.booking.info.contact.info.title') }}</h3>

        <div class="table-responsive">
            <table class="table">
                <tbody>
                    <tr>
                        <td class="header">Email</td>
                        <td class="col">
                            {{ userEmail }}
                        </td>
                    </tr>
                    <tr>
                        <td class="header">Nom</td>
                        <td class="col">
                            {{ userName }}
                        </td>
                    </tr>
                    <tr v-if="userPhone != null">
                        <td class="header">Telephone</td>
                        <td class="col">
                            {{ userPhone }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h3 v-if="userAddress != null">{{ $t('hospitality.booking.info.contact.address.title') }}</h3>

        <Address :address="userAddress" v-if="userAddress != null"></Address>

    </div>
</template>

<style>

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDay, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';
import { diff } from '@igotweb-node-api-utils/date';
import Address from './Address.vue';
import { useAgencyAdmin } from '../../../../composables/useAgencyAdmin';
import { useUsers } from '../../../../../../composables/useUsers';

export default defineComponent({
    props: {
        booking: {
          type: Object as PropType<any>,
          required: true
        }
    },
    components: {
        Address
    },
    setup(props, context) {
        const app = getApp();
        const store = useStore();

        const { selectedSite } = useAgencyAdmin(props, context);
        const { getUserFromID } = useUsers({
            ...props,
            site: ref(selectedSite.value)
        }, context);

        const { booking } = toRefs(props);

        const user = computed(() => {
            var userID = booking.value.user_id;
            var user = getUserFromID(userID);
            return user;
        })

        const userEmail = computed(() => {
            if(user.value) {
                return user.value.email;
            }
            return ""
        })

        const userName = computed(() => {
            if(user.value) {
                return user.value.profile.firstName + " " + user.value.profile.lastName;
            }
            return ""
        })

        const userPhone = computed(() => {
            if(user.value && user.value.profile.phones && user.value.profile.phones.length > 0) {
                return user.value.profile.phones[0].formatted;
            }
            return null;
        })

        const userAddress = computed(() => {
            if(user.value) {
                return user.value.profile.address;
            }
            return null;
        })

        return {
            booking,
            user,
            userEmail,
            userAddress,
            userName,
            userPhone
        }

    }
})

</script>