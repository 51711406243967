<template>
    <div class="table-responsive address">
        <table class="table">
            <tbody>
                <tr>
                    <td class="header">Adresse</td>
                    <td class="col">
                        {{address.addressLines[0]}}
                        <span v-for="index in address.addressLines.length-1" :key="index">
                        <br/> {{address.addressLines[index]}}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="header">Code postal</td>
                    <td class="col">
                        {{address.postalCode}}
                    </td>
                </tr>
                <tr>
                    <td class="header">Ville</td>
                    <td class="col">
                        {{address.city}}
                    </td>
                </tr>
                <tr>
                    <td class="header">Pays</td>
                    <td class="col">
                        {{$t("COUNTRIES."+address.countryCode.toUpperCase())}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style>

/*
.address .table td.header {
    padding-right:15px;
}

.address .table td {
    border-top:0px;
    white-space: nowrap;
    padding:0px;
}
*/

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import { ContactInterface } from '@fwk-client/modules/shop/helpers/contact';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
    components: {
    },
    computed: {},
})
export default class Address extends Vue {

    @Prop({
        type: Object,
        required: true
    }) readonly address!: any
    
}

</script>