<template>
  
    <div class="review-booking">

      <CartRecap :booking="booking"></CartRecap>

      <hr/>

      <ContactRecap :booking="booking"></ContactRecap>

      <hr/>

      <PaymentRecap :booking="booking"></PaymentRecap>

      <Cancel v-if="allowCancel" :booking="booking" v-on:booking-updated="onItemUpdated"></Cancel>
      
    </div>

</template>

<style>

  .review-booking hr {
    border-top: 2px solid rgba(0,0,0,.3);
    margin-left: -20px;
    margin-right: -20px;
  }

  .review-booking td.header {
    white-space: nowrap;
  }

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import CartRecap from '../recap/CartRecap.vue';
import ContactRecap from '../recap/ContactRecap.vue';
import PaymentRecap from '../recap/PaymentRecap.vue';
import Cancel from '../cancel/Cancel.vue';

export default defineComponent({
  props: {
      booking : {
        type: Object,
        required: true
      }
  },
  emits: ["booking-updated"],
  components: {
    CartRecap,
    ContactRecap,
    PaymentRecap,
    Cancel
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { booking } = toRefs(props);

    const onItemUpdated = (item:any) => {
      context.emit("booking-updated", item);
    }

    const allowCancel = computed(() => {
      if(!booking.value) {
        return false;
      }
      return ['TO_VALIDATE', 'CANCELLED', 'CLOSED'].indexOf(booking.value.status) < 0;
    })

    return {
      allowCancel,
      booking,
      onItemUpdated
    }
  }
})
</script>