<template>
  <div class="row m-b-lg m-t-lg">
    <div class="col-md-6">
      <div v-if="logoURL" class="profile-image">
          <img :src="logoURL" class="rounded-circle circle-border" alt="logo">
      </div>
      <div :class="(logoURL ? 'profile-info' : '')">
        <h2 class="no-margins">{{ name }}</h2>
        <p>
          
        </p>
      </div>
    </div>
    <div class="col-md-6">
        <AgencySwitch v-if="agencies.length > 1" class="mb-3"></AgencySwitch>
    </div>
    
    <div v-if="siteValidationLink" class="col-md-12">
      <a v-if="siteValidationLink" class="btn btn-primary" :href="siteValidationLink" target="_blank">{{$t('hospitality.deployment.validation.button-site', {'domain': siteTopLevelDomain})}}</a>
    </div>
  </div>
</template>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import AgencySwitch from './AgencySwitch.vue';

import { useAgencyAdmin } from '../../composables/useAgencyAdmin';
import { useSiteAdmin } from '../../../cms/composables/useSiteAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      AgencySwitch
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedAgency, agencies, selectedSite } = useAgencyAdmin(props, context);
      const { getSiteValidationLink } = useSiteAdmin(props, context);

      const logoURL = null;

      const name = computed(() => {
        if(selectedAgency && selectedAgency.value.agency) { return selectedAgency.value.agency.name; }
        return "";
      })

      const id = computed(() => {
        if(selectedAgency) { return selectedAgency.value.agency._id; }
        return "-";
      })

      const siteTopLevelDomain = computed(() => {
        return selectedSite.value ? selectedSite.value.topLevelDomain : undefined;
      })

      const siteValidationLink = computed(() => {
        return getSiteValidationLink(selectedSite.value);
      });

      return { 
        agencies,
        selectedAgency,
        logoURL,
        name,
        id,
        siteTopLevelDomain,
        siteValidationLink
      }
    }
})
</script>