<template>
  <div class="ibox bookings">
    <div class="ibox-title">
      <h2>{{ $t('hospitality.bookings.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row m-b-md">
        <div class="col-lg-6">
          <Select
            v-if="apartments.length > 0"
            :labelClass="{'col-auto':true, 'col-form-label':true}"
            :fieldClass="{'col':true}"
            :label="$t('hospitality.availabilities.list.select.label')"
            :placeholder="$t('hospitality.availabilities.list.select.placeholder')"
            :selectOptions="{
              options: apartments,
              getOptionLabel : option => getLocalizedText(option.title),
              reduce : option => option._id
            }"
            v-bind:value.sync="filters.apartmentID" 
            :labelAsColumn="true"
          />
        </div>
        <div class="col-auto">
          <DropdownList
            label="Status"
            :list="availableStatus"
            :getLabel="getStatusLabel"
            v-bind:value.sync="filters.status" 
          />
        </div>
        <div class="col-auto refreshButton">
          <a class="btn btn-white" @click="onRefreshClick">
            <i class="fa fa-refresh"></i>
          </a>
        </div>
      </div>

      <div class="row">

        <b-pagination
            v-model="currentPage"
            :total-rows="bookings.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

      </div>

      <div class="row">

        <div v-if="showNumberOfRows">{{ $t('hospitality.bookings.number-of-bookings', {nbBookings:numberOfRows}) }}</div>
        
        <b-table 
            outlined striped
            :items="bookings"
            :fields="listFields"
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
            :empty-text="$t('hospitality.bookings.no-booking')"
            ref="listItems">
          <template v-slot:cell(select)="row">
            <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
          </template>  
          <template v-slot:cell(apartment)="row">
            {{ row.item.products[0].apartment.code }}
            <div>
              <a href="javascript:void(0)" @click="showBookingRecapModal(row.item)">{{$t('hospitality.bookings.options.recap')}}</a> 
            </div>
          </template>  
          <template v-slot:cell(dates)="row">
            <div>{{ $t("hospitality.booking.rental.check-in") }} <b> {{ formatDate(row.item.products[0].startDate) }}</b></div>
            <div> {{ $t("hospitality.booking.rental.check-out") }} <b> {{ formatDate(row.item.products[0].endDate) }}</b></div>
            <div>{{ $t("hospitality.booking.rental.number-of-nights") }} <b>  {{ getNumberOfNights(row.item.products[0]) }} </b></div>
          </template>   
          <template v-slot:cell(options)="row">
            <span v-if="row.item.status == 'TO_VALIDATE'">
              <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('hospitality.bookings.options.validate')}}</a>
            </span>
            <span v-else-if="row.item.status == 'PAYMENT'">
              <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('hospitality.bookings.options.payment')}}</a>
            </span>
            <span v-if="(row.item.options && row.item.options.isRemoveAllowed) || row.item.status == 'CANCELLED'">
              /
              <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('hospitality.bookings.options.delete')}}</a>
            </span>
          </template>  
        </b-table>

        <RecapModal ref="bookingRecapModal" v-on:booking-updated="onItemUpdated" />

        <b-modal size="xl" ref="updateModal" :title="updateModalTitle" hide-footer lazy>
          <Update :booking="itemToUpdate" v-on:booking-updated="onItemUpdated" />
        </b-modal>

        <b-modal size="lg" ref="removeItemModal" 
            :title="$t('hospitality.booking.remove.title', {bookingID: itemToRemove._id})"
            @ok="removeItem">
          <h3>{{$t('hospitality.booking.remove.query', {bookingID: itemToRemove._id})}}</h3>
          <p v-if="itemToRemove.options && itemToRemove.options.removeReason">
            {{$t('hospitality.booking.remove.reasons.'+itemToRemove.options.removeReason,)}}
          </p>
        </b-modal>

      </div>

    </div>
  </div>
</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { languagesTypes } from '@fwk-client/store/types';
import { formatDate, formatDay, FormatDateType } from '@igotweb-node-api-utils/formatter';
import Select from '@fwk-client/components/panels/input/Select.vue';
import DropdownList from '@fwk-client/components/panels/input/DropdownList.vue';
import * as hospitality from '@root/src/client/utils/hospitality';

import { useBookingAdmin } from '../../../composables/useBookingAdmin';
import { useApartmentAdmin } from '../../../composables/useApartmentAdmin';
import { useAgencyAdmin } from '../../../composables/useAgencyAdmin';
import { useUsers } from '../../../../../composables/useUsers';
import { diff } from '@igotweb-node-api-utils/date';

import Update from './modals/Update.vue';
import RecapModal from './RecapModal.vue';

export default defineComponent({
  props: {
  },
  components: {
    Select,
    Update,
    RecapModal,
    DropdownList
  },
  emits: ["booking-updated", "booking-removed"],
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedSite } = useAgencyAdmin(props, context);
    const { getUserFromID } = useUsers({
        ...props,
        site: ref(selectedSite.value)
      }, context);

    const { apartments, isListLoading:isApartmentsListLoading }  = useApartmentAdmin(props, context);
    const { bookings, isListLoading, updateListBookings, filters, removeBooking, availableStatus  }  = useBookingAdmin(props, context);
    updateListBookings();

    const itemToRemove:Ref<any> = ref({});
    const itemToUpdate:Ref<any> = ref({});

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value || isApartmentsListLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const updateModal:Ref<HTMLElement|null> = ref(null);
    const bookingRecapModal:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(true);

    const numberOfRows:Ref<number> = computed(() => {
        return bookings.value.length
    })

    const perPage:number = 10;
    const currentPage:Ref<number> = ref(1);

    const bookingStatusFormatter = () => {
      return (value:any, key:any, item:any) => {
        return hospitality.getBookingStatusLabel(app, item);
      }
    }

    const getNumberOfNights = (cartProduct:any) => {
        return diff(cartProduct.startDate,cartProduct.endDate).days;
    }

    const listFields:Ref<any[]> = ref([
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "apartment",
        label: ""
      },
      {
        key: "dates",
        label: ""
      },
      {
        key: "email",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          var userID = item.user_id;
          var user = getUserFromID(userID);
          if(user) {
            return user.email;
          }
          return userID;
        }
      },
      {
        key: "contact",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          var userID = item.user_id;
          var user = getUserFromID(userID);
          if(user) {
            return user.profile.firstName + " " + user.profile.lastName;
          }
          return userID;
        }
      },
      {
        key: "totalAmount",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return app.formatPriceAmount(value);
        }
      },
      {
        key: "status",
        label: "",
        formatter: bookingStatusFormatter()
      },
      {
        key: "options"
      }
    ]);

    onMounted(() => {
      updateTableLabels();
      enableAutoRefresh();
    })

    const updateTableLabels = () => {
      listFields.value[1].label = app.$t('hospitality.bookings.headers.apartment') as string;
      listFields.value[2].label = app.$t('hospitality.bookings.headers.dates') as string;
      listFields.value[3].label = app.$t('hospitality.bookings.headers.email') as string;
      listFields.value[4].label = app.$t('hospitality.bookings.headers.name') as string;
      listFields.value[5].label = app.$t('hospitality.bookings.headers.amount') as string;
      listFields.value[6].label = app.$t('hospitality.bookings.headers.status') as string;
      listFields.value[7].label = app.$t('hospitality.bookings.headers.options') as string;
    }

    const enableAutoRefresh = () => {
      setTimeout(() => {
        // When the component is unloaded, the timeout is still active
        updateListBookings();
        enableAutoRefresh();
      }, 5000*60);
    }

    const onRefreshClick = () => {
      updateListBookings();
    }

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const getStatusLabel = (statusCode:string) => {
      return hospitality.getBookingStatusLabel(app, {
        status:statusCode
      });
    }

    const updateModalTitle = computed(() => {
      if(itemToUpdate.value.status == 'TO_VALIDATE') {
        return app.$t('hospitality.booking.validate.title', {bookingID: itemToUpdate.value._id}) as string;
      }
      else if(itemToUpdate.value.status == 'PAYMENT') {
        return app.$t('hospitality.booking.payment.title', {bookingID: itemToUpdate.value._id}) as string;
      }
      return "-"
    })

    const formatDate = (date:Date) => {
      return formatDay(date, currentLanguageCode.value, {type: FormatDateType.LONG});
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateModal.value.show()
    }

    const showBookingRecapModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      bookingRecapModal.value.show(itemToUpdate.value)
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
      updateListBookings();
      context.emit("booking-updated", item);
    }

    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const removeItem = () => {
      removeBooking(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};
        context.emit("booking-removed");
      })
    }

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
       updateTableLabels();
      },
      { deep: false }
    )

    watch(
      () => filters.apartmentID,
      (val:any, oldVal:any) => {
        updateListBookings();
      },
      { deep: false }
    )

    return {
      currentLanguageCode,
      listLoading,
      bookings,
      apartments,
      filters,
      availableStatus,
      onRefreshClick,
      getStatusLabel,

      listItemsSelected,
      listItems,
      listFields,

      showNumberOfRows,
      numberOfRows,
      perPage,
      currentPage,

      itemToUpdate,
      onItemUpdated,

      formatDay,

      updateModalTitle,
      updateModal,
      showUpdateModal,
      getNumberOfNights,
      formatDate,

      itemToRemove,
      removeItem,
      confirmRemoveItem,
      removeItemModal,

      showBookingRecapModal,
      bookingRecapModal
    }
  }
})
</script>