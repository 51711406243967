<template>
  <b-modal size="lg" ref="bookingRecapModal" :title="$t('hospitality.booking.recap.title', {bookingID: booking != null ? booking._id : ''})" hide-footer lazy>
    <Recap v-if="booking != null" :booking="booking" v-on:booking-updated="onItemUpdated" />
  </b-modal>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef, toRefs, defineExpose } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { languagesTypes } from '@fwk-client/store/types';

import Recap from './modals/Recap.vue';

export default defineComponent({
  props: {
  },
  components: {
    Recap,
  },
  emits: ["booking-updated"],
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const booking:Ref<any> = ref(null);

    const bookingRecapModal:Ref<HTMLElement|null> = ref(null);

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemUpdated = (item:any) => {
      booking.value = item;
      context.emit("booking-updated", item);
    }

    const show = (bookingToShow:any) => {
      booking.value = bookingToShow;
      // @ts-ignore
      bookingRecapModal.value.show()
    }

    const hide = () => {
      // @ts-ignore
      bookingRecapModal.value.hide()
    }

    context.expose({
      show,
      hide
    })

    return {
      currentLanguageCode,
      onItemUpdated,
      bookingRecapModal,
      booking
    }
  }
})
</script>