<template>

  <div v-if="booking.status == 'TO_VALIDATE'" class="validate-booking">

    <hr/>

    <button class="btn btn-secondary" @click="showCancelForm" type="button">{{$t('hospitality.booking.cancel.button')}}</button>
    <button class="btn btn-primary" @click="showConfirmForm" type="button">{{$t('hospitality.booking.validate.confirm.button')}}</button>

    <div :style="{ display : isCancel ? 'block' : 'none' }">
      <hr/>
      <h3>{{ $t('hospitality.booking.cancel.title') }}</h3>
      <p>{{ $t('hospitality.booking.cancel.note') }}</p>
      <LocalizedText 
        v-bind:value.sync="form.cancel.reason" 
        :label="$t('hospitality.booking.cancel.reason')" 
        :languageCodes="availableLanguageCodes" 
        labelAsColumn 
      />
      
      <button class="btn btn-primary ladda-button cancel" data-style="zoom-in" type="button" @click="onCancelFormSubmit">{{$t('hospitality.booking.cancel.button')}}</button>
    </div>

    <div :style="{ display : isConfirm ? 'block' : 'none' }">
      <hr/>
      <h3>{{ $t('hospitality.booking.validate.confirm.title') }}</h3>
      <p>{{ $t('hospitality.booking.validate.confirm.note') }}</p>
      <LocalizedText 
        v-bind:value.sync="form.confirm.message" 
        :label="$t('hospitality.booking.validate.confirm.message')" 
        :languageCodes="availableLanguageCodes" 
        labelAsColumn 
      />
      <LocalizedText 
        v-bind:value.sync="form.cancel.reason" 
        :label="$t('hospitality.booking.validate.cancel.reason')" 
        :languageCodes="availableLanguageCodes" 
        labelAsColumn 
      />
      <button class="btn btn-primary ladda-button confirm" data-style="zoom-in" type="button" @click="onConfirmFormSubmit">{{$t('hospitality.booking.validate.confirm.button')}}</button>
    </div>
  
  </div>

</template>

<style>

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';

import * as Ladda from 'ladda';

import { useBookingAdmin } from '../../../../composables/useBookingAdmin';
import { useAgencyAdmin } from '../../../../composables/useAgencyAdmin';

export default defineComponent({
  props: {
      booking : {
        type: Object,
        required: true
      }
  },
  emits: ["booking-updated"],
  components: {
    LocalizedText
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { validateConfirm, cancel } = useBookingAdmin({}, context);
    const { selectedAgency } = useAgencyAdmin({}, context);

    const isCancel:Ref<boolean> = ref(false);
    const isConfirm:Ref<boolean> = ref(false);

    const { booking } = toRefs(props);

    const availableLanguageCodes = computed(() => {
      return ["fr", "en"];
    })

  
    const form = reactive({
      cancel : {
        reason : {}
      },
      confirm : {
        message : {}
      }
    })

    var laddaCancelSubmit:Ladda.LaddaButton|null = null;
    var laddaConfirmSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var cancelButton:HTMLButtonElement|null = document.querySelector( '.validate-booking button.cancel' );
      laddaCancelSubmit = Ladda.create(cancelButton!);

      var confirmButton:HTMLButtonElement|null = document.querySelector( '.validate-booking button.confirm' );
      laddaConfirmSubmit = Ladda.create(confirmButton!);

      // We check if we have default messages for cancel or confirm
      if(selectedAgency.value && selectedAgency.value.agency && selectedAgency.value.agency.configuration) {
        var configuration = selectedAgency.value.agency.configuration;
        if(configuration.bookingCancelationNotificationDefaultReason) {
          form.cancel.reason = configuration.bookingCancelationNotificationDefaultReason;
        }
        if(configuration.bookingValidationNotificationDefaultMessage) {
          form.confirm.message = configuration.bookingValidationNotificationDefaultMessage;
        }
      }
      
    });

    const onCancelFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaCancelSubmit!.start();

      cancel(booking.value._id, form.cancel.reason).then((result:any) => {
        if(result.cancelled) {
          context.emit("booking-updated", result.booking);
        }
        laddaCancelSubmit!.stop();
      })
    }

    const onConfirmFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaConfirmSubmit!.start();

      validateConfirm(booking.value._id, form.confirm.message, form.cancel.reason).then((result:any) => {
        if(result.confirmed) {
          context.emit("booking-updated", result.booking);
        }
        laddaConfirmSubmit!.stop();
      })
    }

    const showCancelForm = () => {
      isCancel.value = true;
      isConfirm.value = false;
    }

    const showConfirmForm = () => {
      isConfirm.value = true;
      isCancel.value = false;
    }

    return {
      booking,
      form,
      onCancelFormSubmit,
      onConfirmFormSubmit,
      showCancelForm,
      showConfirmForm,
      isCancel,
      isConfirm,
      availableLanguageCodes
    }
  }
})
</script>