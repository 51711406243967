<template>

  <div v-if="booking.status == 'PAYMENT'" class="payment-confirm">

    <button class="btn btn-primary" @click="showConfirmForm" type="button">{{$t('hospitality.booking.payment.confirm.button')}}</button>

    <div :style="{ display : isConfirm ? 'block' : 'none' }">
      <hr/>
      <h3>{{ $t('hospitality.booking.payment.confirm.title') }}</h3>
      <p>{{ $t('hospitality.booking.payment.confirm.note') }}</p>
      <button class="btn btn-primary ladda-button confirm" data-style="zoom-in" type="button" @click="onConfirmFormSubmit">{{$t('hospitality.booking.payment.confirm.button')}}</button>
    </div>
  
  </div>

</template>

<style>

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import * as Ladda from 'ladda';

import { useBookingAdmin } from '../../../../composables/useBookingAdmin';

export default defineComponent({
  props: {
      booking : {
        type: Object,
        required: true
      }
  },
  emits: ["booking-updated"],
  components: {

  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { paymentConfirm } = useBookingAdmin({}, context);

    const isConfirm:Ref<boolean> = ref(false);

    const { booking } = toRefs(props);

    var laddaConfirmSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var confirmButton:HTMLButtonElement|null = document.querySelector( '.payment-confirm button.confirm' );
      laddaConfirmSubmit = Ladda.create(confirmButton!);
      
    });

    const onConfirmFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaConfirmSubmit!.start();

      paymentConfirm(booking.value._id).then((result:any) => {
        if(result.confirmed) {
          context.emit("booking-updated", result.booking);
        }
        laddaConfirmSubmit!.stop();
      })
    }

    const showConfirmForm = () => {
      isConfirm.value = true;
    }

    return {
      booking,
      onConfirmFormSubmit,
      showConfirmForm,
      isConfirm
    }
  }
})
</script>