import Vue from '@fwk-node-modules/vue';

/**
 * getPaymentMethodLabel
 */
export function getPaymentMethodLabel (vm:Vue, booking:any) {
    var methodOfPayment = booking.transaction.method.type
    return vm.$t('hospitality.bookings.methodsOfPayment.'+methodOfPayment) as string;
}

/**
 * getPaymentStatusLabel
 */
export function getPaymentStatusLabel (vm:Vue, booking:any) {
    var transactionStatus = booking.transaction.status;
    return vm.$t('hospitality.bookings.paymentStatus.'+transactionStatus) as string;
}

/**
 * getBookingStatusLabel
 */
export function getBookingStatusLabel (vm:Vue, booking:any) {
    var bookingStatus = booking.status;
    return vm.$t('hospitality.bookings.bookingStatus.'+bookingStatus) as string;
}