import { toRefs, Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';

interface UsersInput {
  user?:any,
  site?:any
}

export function useUsers(props:UsersInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();

  const { user, site } = props;

  const filters:any = reactive({
    status:["ACTIVE", // The user which are active
    "INACTIVE"], // The users which are not active
    displayName: undefined,
  })

  var availableStatus:Ref<string[]> = ref(["ACTIVE", "INACTIVE"]);

  const form = reactive({
    login: '',
    email: '',
    roles : [], // We put comma + space as separator between roles.
    password : '',
    isActive : false,
    isTotp : false
  });

  const updateUserFormForUpdate = (user:any) => {
    form.login = (user) ? user.login : undefined,
    form.email = (user) ? user.email : undefined,
    form.roles = (user) ? user.roles : undefined, // We put comma + space as separator between roles.
    form.password = '',
    form.isActive = (user) ? user.isActive : undefined,
    form.isTotp = (user) ? user.hasSecretKey : undefined
  }

  if(user) {
    updateUserFormForUpdate(user);
  }

  var availableRoles:string[] = [];
  var listUsers:Ref<any[]> = ref([]);

  const selectedSite:Ref<any> = (site && site.value) ? site : ref(undefined);

  const updateSite = (site:any) => {
    selectedSite.value = site;
    return updateUsers();
  }

  const getAvailableRoles = () => {
    // We need to get the list of available roles for the current logged in user
    var options:api.ApiVueOptions =  { app }
    api.getAPI('/api/admin/roles/available-roles', options).then((response:any) => {
      if(response.roles) {  
        availableRoles = response.roles;
      }
    });
  }
  getAvailableRoles();

  const updateInputWithTargetDB = (input:any) => {
    // We check if we have a target DB for the content
    var targetDB = store.getters['application/'+applicationTypes.getters.GET_ADMIN_TARGET_DB];
    if(targetDB) {
      input.targetDB = targetDB;
    }
  }

  const updateUsers = async () => {
    var options:api.ApiVueOptions =  { app }

    var input = {};

    var path = '/api/admin/users/list';
    if(selectedSite.value) {
      // We update target DB when we look for site users
      updateInputWithTargetDB(input);
      path = '/api/admin/cms/site/'+selectedSite.value._id+'/users/list';
    }

    try {
      var response = await api.postAPI(path, input, options);
      if(response.users) {  
        listUsers.value = response.users;
      }
      else {
        listUsers.value = [];
      }
    }
    catch(error:any) {
      console.log(error);
    }
  }
  updateUsers();

  const resetTotp = async () => {

    var input:any = {
      "userID" : user ? user._id : undefined
    }
    var options:api.ApiVueOptions =  { app }
    
    var response = await api.postAPI('/api/admin/users/reset-totp', input, options);
    if(response.reseted) {  
      var resultUser = response.user;
      // We emit event as user is updated
      emit('user-updated', resultUser);
    }
  }

  const updateUser = async () => {

    var input = {
      "userID" : user ? user._id : undefined,
      "login" : form.login,
      "email" : form.email,
      "roles" : form.roles,
      "password" : form.password,
      "isActive" : form.isActive,
      "isTotp" : form.isTotp
    }
    var options:api.ApiVueOptions =  { app }

    var path = '/api/admin/users/update';
    if(selectedSite.value) {
      path = '/api/admin/cms/site/'+selectedSite.value._id+'/users/update';
    }
    
    var response = await api.postAPI(path, input, options);
    if(response.updated) {  
      var resultUser = response.user;
      // We emit event as user is updated
      emit('user-updated', resultUser);

      // We update the list of users
      await updateUsers();

      // We update the form based on values returned by backend (in case not updated)
      form.login = resultUser.login;
      form.email = resultUser.email;
    }
    // We reset the field
    form.password = "";
  }

  const activateUser = async (userToActivate:any) => {

    var input = {
      "userID" : userToActivate._id
    }
    var options:api.ApiVueOptions =  { app }

    var path = '/api/admin/users/activate';
    if(selectedSite.value) {
      path = '/api/admin/cms/site/'+selectedSite.value._id+'/users/activate';
    }
    
    var response = await api.postAPI(path, input, options);
    if(response.activated) {
      // We update the list of users
      await updateUsers();
    }
  }

  const removeUser = async (userToRemove:any) => {

    var input = {
      "userID" : userToRemove._id
    }
    var options:api.ApiVueOptions =  { app }

    var path = '/api/admin/users/remove';
    if(selectedSite.value) {
      path = '/api/admin/cms/site/'+selectedSite.value._id+'/users/remove';
    }
    
    var response = await api.postAPI(path, input, options);
    if(response.removed) {
      // We update the list of users
      await updateUsers();
    }
  }

  const getUserFromID = (userID:string) => {
    var indexUser = listUsers.value.map((user:any) => {
       return user._id;
     }).indexOf(userID);
     if(indexUser > -1) {
      return listUsers.value[indexUser];
     }
     return undefined;
   }

  return {
    updateSite,
    updateUsers,
    updateUser,
    activateUser,
    removeUser,
    resetTotp,
    getUserFromID,
    availableRoles,
    form,
    listUsers,
    filters,
    availableStatus,
    updateUserFormForUpdate
  }
  
}