<template>
  <validation-provider ref="validator" :name="id" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes, 'localizedText':true}">
      <label :for="id" :class="{...labelCSSClass}">{{ inputLabel }} <small v-if="required">*</small></label>
      <div :class="{...fieldCSSClass}">
        <ul class="nav nav-tabs">
          <li v-for="(languageCode, index) in languageCodes" :key="index">
            <a :class="{'nav-link':true, 'active':(languageCode == selectedLanguageCode)}" href="javascript:void(0)" @click="selectedLanguageCode = languageCode">
              <img :src="require('@public/img/flags/' + languageCode + '.png')" :alt="$t('languages.'+ languageCode)" /> 
            </a>
          </li>
        </ul>
        <textarea v-if="input" :id="id" :placeholder="inputPlaceholder" :class="{...inputCSSClass, 'required':required, ...classes}" v-model="input[selectedLanguageCode]" :required="required"/>
        <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<style>
  .localizedText .nav.nav-tabs li {
    margin-bottom: -1px;
  }

  .localizedText textarea {
    border-top:0px;
  }

  .localizedText.has-success .nav-tabs {
    border-color: #1ab394;
  }

  .localizedText.has-error .nav-tabs {
    border-color: #ed5565;
  }

  .localizedText.has-success .nav-tabs .nav-link.active {
    border-color: #1ab394 #1ab394 #fff;
  }

  .localizedText.has-error .nav-tabs .nav-link.active {
    border-color: #ed5565 #ed5565 #fff;
  }
</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class LocalizedText extends mixins<GenericInput<any>>(GenericInput) {

  @Prop({
    type: Array,
    required: true,
  }) readonly languageCodes!: string[]

  selectedLanguageCode:string = this.languageCodes[0];

  get inputLabel() {
    return (this.label && this.label != "") ? this.label : "";
  }

  get inputPlaceholder() {
    return (this.placeholder && this.placeholder != "") ? this.placeholder : "";
  }

  created() {
    if(!this.value || this.value == "") {
      this.setDefaultInput();
    }
  }

  validate() {
    // @ts-ignore
    return this.$refs.validator.validate();
  }

  setDefaultInput() {
    this.input = this.languageCodes.reduce((acc:any,current)=> ( acc[current]='' ,acc) ,{});
  }

  @Watch('value')
  onValueChange(val: any, oldVal: any) {
    if(!val || val == "") {
      this.setDefaultInput();
    }
    else {
      this.input = val;
    }
    // @ts-ignore
    this.$refs.validator.validate(val);
  }

  @Watch('input', { deep: true })
  onInputChange(val: any, oldVal: any) {
    // We check that we have at least one non empty value
    for(var languageCode of this.languageCodes) {
      if(val && val[languageCode] && val[languageCode] != "") {
        // We found a non empty value
        return this.$emit('update:value', val);
      }
    }
    // When all values are empty, the localized text should be undefined
    return this.$emit('update:value', undefined);
  }
  
}
</script>