<template>
  <validation-provider ref="validator" :name="id" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <b-dropdown class="dropdown-list" 
          ref="dropdown"
          split
          split-variant="outline-secondary"
          >
        <template slot="button-content">
          <label :class="{...labelCSSClass,'top-dropdown-list':true}" :for="id">
            <input :id="id" type="checkbox" :checked="input.length > 0" @click="onDropdownTopItemClick($event)" />
            <span>{{ inputLabel }}<small v-if="required">*</small></span>
          </label>
        </template>

        <b-dropdown-item v-for="(item, index) in list" :key="index + '-' + item">
          <label class="item-checkbox" @click="onDropdownItemClick(item, $event)">
            <input type="checkbox" v-model="input" :value="item" />
            <span>{{ getListLabel(item) }}</span>
          </label>
        </b-dropdown-item>
      </b-dropdown>
      <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
    </div>
  </validation-provider>
</template>

<style>
  .dropdown-list .top-dropdown-list, .dropdown-list .item-checkbox {
    margin:0px;
  }

  .dropdown-list .top-dropdown-list input {
    vertical-align: middle;
    margin-right: 3px;
  }
  .dropdown-list .top-dropdown-list span {
    vertical-align: middle;
  }
  .dropdown-list .item-checkbox input {
    vertical-align: middle;
  }
  .dropdown-list .item-checkbox span {
    vertical-align: middle;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class DropdownList extends mixins<GenericInput<string[]>>(GenericInput) {

  @Prop({
    type: Array,
    required: true,
    default: []
  }) readonly list!: string[];

  @Prop({
    type: Function,
    required: false,
  }) readonly getLabel!: Function;

  @Prop({
    type: Object,
    required: false,
  }) readonly labels!: any;

  input = this.value ? this.value : [];

  get inputLabel() {
    return (this.label && this.label != "") ? this.label : "";
  }

  getListLabel(item:string) {
    if(this.getLabel) {
      return this.getLabel.call(this,item);
    }
    else if(this.labels) {
      return this.labels[item];
    }
    return item;
  }

  onDropdownTopItemClick(evt:Event) {
    if(this.input.length > 0) {
      Vue.set(this,"input",[]);
    }
    else {
      Vue.set(this,"input",[...this.list]);
    }
  }

  onDropdownItemClick(status:string, evt:Event) {
    evt.stopPropagation();
  }

  validate() {
    // @ts-ignore
    return this.$refs.validator.validate();
  }

  @Watch('value')
  onValueChange(val: any, oldVal: any) {
    this.input = val;
    // @ts-ignore
    this.$refs.validator.validate(val);
  }
  
}
</script>