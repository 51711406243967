import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as hospitalityTypes } from '@root/src/client/modules/hospitality/store';
import { useAgencyAdmin } from "./useAgencyAdmin";
import { LocalizedText } from "@igotweb-node-api/api/models/interface/localization";

interface BookingAdminInput {
  storage?:"local"|"store";
}

export function useBookingAdmin(props:BookingAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedAgency, callAgencyAdmin } = useAgencyAdmin(props, {emit})

  const filters:any = reactive({
    status:["TO_VALIDATE", // The booking is created and need validation, payment is needed.
    "PAYMENT", // The booking is created and validate payment is needed.
    "CANCELLED", // The booking has been cancelled by the merchant.
    "CONFIRMED"], // The booking payment is validated
    dateRange : {
      startDate: null,
      endDate: new Date()
    },
    apartmentID: undefined,
  })

  var storage = props.storage ? props.storage : "store";

  var isListLoading:Ref<boolean> = ref(false);
  var availableStatus:Ref<string[]> = ref([]);

  const localBookings = ref([]);

  const bookings = computed(() => {
    if(storage == "local") {
      return localBookings.value;
    }
    return store.getters['hospitality/'+hospitalityTypes.getters.GET_USER_BOOKINGS];
  })

  const getBookingFromID = (bookingID:string) => {
    var booking = bookings.value.filter((booking:any) => {
      return booking._id == bookingID
    })
    if(booking.length == 1) { return booking[0]; }
    return null;
  }

  const updateListBookings = async () => {
    var input:any = {
      status:filters.status,
      apartmentID:filters.apartmentID ? filters.apartmentID : undefined
    }

    isListLoading.value = true;
    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/bookings', input);
      if(storage == "local") {
        localBookings.value = response.bookings ? response.bookings : [];
      }
      else {
        store.commit('hospitality/'+hospitalityTypes.mutations.SET_USER_BOOKINGS, response.bookings ? response.bookings : []);
      }
      availableStatus.value = response.availableStatus ? response.availableStatus : [];
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  const removeBooking = async (bookingID:string) => {
    isListLoading.value = true;
    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/booking/'+bookingID+'/remove');
      if(response.removed) {
        updateListBookings();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  const validateConfirm = async (bookingID:string, message?:LocalizedText, reason?:LocalizedText) => {
    var input = {
      "message" : message,
      "reason" : reason
    }
    isListLoading.value = true;
    var result:any = {
      confirmed: false
    }
    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/booking/'+bookingID+'/validate/confirm', input);
      if(response.confirmed) {
        updateListBookings();
        result.confirmed = true;
        result.booking = response.booking;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const checkExistingBookings = async (bookingID:string) => {
    var result:any = {
      bookings: []
    }
    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/booking/'+bookingID+'/validate/check-existing-bookings');
      if(response.bookings) {
        result.bookings = response.bookings;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const cancel = async (bookingID:string, reason?:LocalizedText) => {
    var input = {
      "reason" : reason
    }
    isListLoading.value = true;
    var result:any = {
      cancelled: false
    }
    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/booking/'+bookingID+'/cancel', input);
      if(response.cancelled) {
        updateListBookings();
        result.cancelled = true;
        result.booking = response.booking;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const paymentConfirm = async (bookingID:string) => {
    var input = {
    }
    isListLoading.value = true;
    var result:any = {
      confirmed: false
    }
    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/booking/'+bookingID+'/payment/confirm', input);
      if(response.confirmed) {
        updateListBookings();
        result.confirmed = true;
        result.booking = response.booking;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  // We check when there is a new agency selected
  watch(
    () => selectedAgency.value.agency._id,
    (val:any, oldVal:any) => {
      // We update the bookings
      updateListBookings();
    },
    { deep: false }
  )

  return {
    bookings,
    isListLoading,
    filters,
    availableStatus,
    getBookingFromID,
    updateListBookings,
    removeBooking,
    validateConfirm,
    cancel,
    checkExistingBookings,
    paymentConfirm
  }
  
}