<template>
  <div class="ibox bookings">
    <div class="ibox-title">
      <h2>{{ $t('hospitality.booking.validate.existing-bookings.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row">

        <p>{{ $t('hospitality.booking.validate.existing-bookings.note') }}</p>

        <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
      
        <b-table 
            outlined striped
            :items="existingBookings"
            :fields="listFields"
            show-empty
            :empty-text="$t('hospitality.bookings.no-booking')"
            ref="listItems">
          <template v-slot:cell(select)="row">
            <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
          </template>
          <template v-slot:cell(dates)="row">
            <div>{{ $t("hospitality.booking.rental.check-in") }} <b> {{ formatDate(row.item.products[0].startDate) }}</b></div>
            <div> {{ $t("hospitality.booking.rental.check-out") }} <b> {{ formatDate(row.item.products[0].endDate) }}</b></div>
            <div>{{ $t("hospitality.booking.rental.number-of-nights") }} <b>  {{ getNumberOfNights(row.item.products[0]) }} </b></div>
          </template>   
        </b-table>

      </div>

    </div>
  </div>
</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { languagesTypes } from '@fwk-client/store/types';
import { formatDay, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';
import * as hospitality from '@root/src/client/utils/hospitality';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useBookingAdmin } from '../../../../composables/useBookingAdmin';
import { useAgencyAdmin } from '../../../../composables/useAgencyAdmin';
import { useUsers } from '../../../../../../composables/useUsers';
import { useApartmentAdmin } from '../../../../composables/useApartmentAdmin';
import { diff } from '@igotweb-node-api-utils/date';

export default defineComponent({
  props: {
    booking : {
        type: Object,
        required: true
      }
  },
  components: {
    Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedSite } = useAgencyAdmin(props, context);
    const { getUserFromID } = useUsers({
        ...props,
        site: ref(selectedSite.value)
      }, context);

    const { checkExistingBookings } = useBookingAdmin({}, context);

    const { booking } = toRefs(props);

    const listLoading:Ref<boolean> = ref(false);

    const listItemsSelected:Ref<any[]> = ref([]);

    const listItems:Ref<HTMLElement|null> = ref(null);
    
    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const existingBookings:Ref<any[]> = ref([]);
    listLoading.value = true;
    checkExistingBookings(booking.value._id).then((result:any) => {
      listLoading.value = false;
      existingBookings.value = result.bookings;
    })

    const numberOfRows:Ref<number> = computed(() => {
        return existingBookings.value.length
    })

    const bookingStatusFormatter = () => {
      return (value:any, key:any, item:any) => {
        return hospitality.getBookingStatusLabel(app, item);
      }
    }

    const getNumberOfNights = (cartProduct:any) => {
        return diff(cartProduct.startDate,cartProduct.endDate).days;
    }

    const listFields:Ref<any[]> = ref([
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "dates",
        label: ""
      },
      {
        key: "email",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          var userID = item.user_id;
          var user = getUserFromID(userID);
          if(user) {
            return user.email;
          }
          return userID;
        }
      },
      {
        key: "contact",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          var userID = item.user_id;
          var user = getUserFromID(userID);
          if(user) {
            return user.profile.firstName + " " + user.profile.lastName;
          }
          return userID;
        }
      },
      {
        key: "totalAmount",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return app.formatPriceAmount(value);
        }
      }
    ]);

    onMounted(() => {
      updateTableLabels();
    })

    const updateTableLabels = () => {
      listFields.value[1].label = app.$t('hospitality.bookings.headers.dates') as string;
      listFields.value[2].label = app.$t('hospitality.bookings.headers.email') as string;
      listFields.value[3].label = app.$t('hospitality.bookings.headers.name') as string;
      listFields.value[4].label = app.$t('hospitality.bookings.headers.amount') as string;
    }

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const formatDate = (date:Date) => {
      return formatDay(date, currentLanguageCode.value, {type: FormatDateType.LONG});
    }

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
       updateTableLabels();
      },
      { deep: false }
    )

    return {
      currentLanguageCode,
      listLoading,
      existingBookings,

      listItemsSelected,
      listItems,
      listFields,
      
      showNumberOfRows,
      numberOfRows,

      formatDay,

      getNumberOfNights,
      formatDate
    }
  }
})
</script>