<template>
  <section id="hospitality" ref="hospitalityContent">
    <PageTitle :title="$t('hospitality.bookings.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedAgency">
        <div class="col">
          <BookingsToValidate></BookingsToValidate>
        </div>
      </div>
      
      <div class="row" v-if="selectedAgency">
        <div class="col">
          <ListBookings></ListBookings>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

import { useGenericPage } from '@fwk-client/composables/useGenericPage';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';
import Header from '../panels/Header.vue';
import ListBookings from '../panels/bookings/ListBookings.vue';
import BookingsToValidate from '../panels/bookings/BookingsToValidate.vue';

import { useAgencyAdmin } from '../../composables/useAgencyAdmin';

export default defineComponent({
  props: {
  },
  components: {
    PageTitle,
    ListBookings,
    Header,
    BookingsToValidate
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const genericPage = useGenericPage(props, context);
    const { agencies, selectedAgency } = useAgencyAdmin(props, context);

    const hospitalityContent:Ref<HTMLElement|null> = ref(null);

    const breadcrumbs = [{
      label: app.$t('home.dashboard')
    },
    {
      label: app.$t('hospitality.title')
    }]

    onMounted(() => {
      enableIbox(hospitalityContent.value!);
    })

    onBeforeUnmount(() => {
      disableIbox(hospitalityContent.value!);
    })

    return {
      hospitalityContent,
      breadcrumbs,
      selectedAgency
    }
  }
});
</script>