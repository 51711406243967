import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as hospitalityTypes } from '@root/src/client/modules/hospitality/store';
import { useAgencyAdmin } from "./useAgencyAdmin";

interface ApartmentAdminInput {
}

export function useApartmentAdmin(props:ApartmentAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedAgency, callAgencyAdmin } = useAgencyAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);

  const isApartmentUpdateAllowed:Ref<boolean> = ref(false);
  const isApartmentCreateAllowed:Ref<boolean> = ref(false);
  const isApartmentRemoveAllowed:Ref<boolean> = ref(false);
  const isApartmentPublishAllowed:Ref<boolean> = ref(false);

  const updateListApartments = async () => {

    isListLoading.value = true;
    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/apartments');
      store.commit('hospitality/'+hospitalityTypes.mutations.SET_USER_APARTMENTS, response.apartments ? response.apartments : []);
      if(response.options) {
        isApartmentUpdateAllowed.value = response.options.isUpdateAllowed;
        isApartmentCreateAllowed.value = response.options.isCreateAllowed;
        isApartmentRemoveAllowed.value = response.options.isRemoveAllowed;
        isApartmentPublishAllowed.value = response.options.isPublishAllowed;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateListApartments();

  /**
   * selectApartment
   * This method update the selected apartment by loading all associated details
   * @param apartmentID 
   */
  const selectApartment = async (apartmentID:string) => {

    // We need to load the apartment and update the store
    try {
      if(apartmentID) {
        var response = await callAgencyAdmin('/apartment/'+apartmentID);
        store.commit('hospitality/'+hospitalityTypes.mutations.SET_USER_SELECTED_APARTMENT, response.apartment ? response.apartment : undefined);  
      }
      else {
        store.commit('hospitality/'+hospitalityTypes.mutations.SET_USER_SELECTED_APARTMENT, undefined);  
      }
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const apartments = computed(() => {
    return store.getters['hospitality/'+hospitalityTypes.getters.GET_USER_APARTMENTS];
  })

  const selectedApartment = computed(() => {
    return store.getters['hospitality/'+hospitalityTypes.getters.GET_USER_SELECTED_APARTMENT];
  })

  // We check when there is a new agency selected
  watch(
    selectedAgency,
    (val:any, oldVal:any) => {
      // We update the categories when the selected agency is updated
      updateListApartments();
    },
    { deep: false }
  )

  const apartmentForm:any = reactive({
    title: '',
    description: '',
    address: null,
    isActivated: true,
    pictures: [],
    attachements: []
  });

  const updateApartmentFormForUpdate = (apartment:any) => {
    // We ensure we do not keep reference with the root apartment
    apartment = JSON.parse(JSON.stringify(apartment));
    apartmentForm.apartmentID = (apartment && apartment._id) ? apartment._id : undefined,
    apartmentForm.title =  apartment.title
    apartmentForm.address = apartment.address
    apartmentForm.isActivated =  apartment.isActivated
    apartmentForm.summary =  apartment.summary
    apartmentForm.description =  apartment.description
    apartmentForm.pictures = [];
    apartmentForm.attachements = [];
  }

  const createApartment = async (extraForm?:any) => {
    var result:any = {
      created: false
    }

    var input = {
      "agencyID" : selectedAgency.value.agency._id,
      ...apartmentForm,
      ...(extraForm ? extraForm : {})
    }

    var formData = new FormData();
    if(apartmentForm.pictures && apartmentForm.pictures.length > 0) {
      for(var picture of apartmentForm.pictures) {
        formData.append("pictures", picture!, (picture! as File).name);  
      }
    }
    if(apartmentForm.attachements && apartmentForm.attachements.length > 0) {
      for(var attachement of apartmentForm.attachements) {
        formData.append("attachements", attachement!, (attachement! as File).name);  
      }
    }

    try {
      var response = await callAgencyAdmin('/apartments/create', input, formData);
      if(response.created) {  
        // We update the form
        updateApartmentFormForUpdate(response.apartment);

        // We update the list
        updateListApartments();

        result.created = true;
        result.apartment = response.apartment;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateApartment = async (extraForm?:any) => {

    var result:any = {
      updated: false
    }

    var input:any = {
      ...apartmentForm,
      ...(extraForm ? extraForm : {})
    }

    try {
      var response = await callAgencyAdmin('/apartment/'+apartmentForm.apartmentID+'/update', input);
      if(response.updated) {  
        // We update the form
        updateApartmentFormForUpdate(response.apartment);

        // We update the list
        updateListApartments();

        result.updated = true;
        result.apartment = response.apartment;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeApartment = async (apartmentID:string) => {
    isListLoading.value = true;
    try {
      var response = await callAgencyAdmin('/apartment/'+apartmentID+'/remove');
      if(response.removed) {
        updateListApartments();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  const uploadFile = async (apartmentID:string, file:File, type:"picture"|"attachement"):Promise<{uploaded:boolean, file?:any}> => {

    var input = {};
    var formData = new FormData();
    if(file != null) {
      formData.append("upload", file, file.name);  
    }

    var result:{uploaded:boolean, file?:any} = {
      uploaded:false
    }

    try {
      var response = await callAgencyAdmin('/apartment/'+apartmentID+'/upload-'+type, input, formData);
      if(response.uploaded) {
        // We update the list
        updateListApartments();

        result.uploaded = true;
        result.file = response[type];
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const uploadPicture = async (apartmentID:string, picture:File):Promise<{uploaded:boolean, picture?:any}> => {
    return uploadFile(apartmentID, picture, "picture").then((result) => {
      return {
        uploaded: result.uploaded,
        picture: result.file ? result.file : undefined
      }
    })
  }

  const uploadAttachement = async (apartmentID:string, attachement:File):Promise<{uploaded:boolean, attachement?:any}> => {
    return uploadFile(apartmentID, attachement, "attachement").then((result) => {
      return {
        uploaded: result.uploaded,
        attachement: result.file ? result.file : undefined
      }
    })
  }

  const removeFile = async (apartmentID:string, fileID:string, type:"picture"|"attachement") => {

    var input = {
      [type+"ID"]: fileID
    };

    var result:any = {
      removed:false
    }

    try {
      var response = await callAgencyAdmin('/apartment/'+apartmentID+'/remove-'+type, input);
      if(response.removed) {
        // We update the list
        updateListApartments();
        
        result.removed = true;
        result.files = response[type+"s"];
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removePicture = async (apartmentID:string, pictureID:string) => {
    return removeFile(apartmentID, pictureID, "picture").then((result) => {
      return {
        removed: result.removed,
        pictures: result.files
      }
    })
  }

  const removeAttachement = async (apartmentID:string, attachementID:string) => {
    return removeFile(apartmentID, attachementID, "attachement").then((result) => {
      return {
        removed: result.removed,
        attachements: result.files
      }
    })
  }

  const updatePicturesRanking = async(apartmentID:string, pictureIndex:number, direction:"up"|"down") => {
    var input = {
      "pictureIndex" : pictureIndex,
      "direction" : direction
    };

    var result:{updated:boolean, apartment?:any, pictures?:any[]} = {
      updated:false
    }

    try {
      var response = await callAgencyAdmin('/apartment/'+apartmentID+'/pictures-update-ranking', input);
      if(response.updated) {
        // We update the list
        updateListApartments();

        result.updated = true;
        result.apartment = response.apartment;
        result.pictures = response.pictures;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateRanking = async(apartmentID:string, direction:string) => {

    var input = {
      "direction" : direction
    }

    isListLoading.value = true;
    try {
      var response = await callAgencyAdmin('/apartment/'+apartmentID+'/update-ranking', input);
      if(response.updated) {
        await updateListApartments();
      }
      isListLoading.value = false;
      return response;
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return false;
  }

  const getPublishTargetApartment = (apartmentID:string) => {
    return callAgencyAdmin('/apartment/'+apartmentID+'/deployment/apartment').then((response:any) => {
      return response.apartment
    });
  }

  const publishApartment = async (apartmentID:string) => {
    var result:any = {
      published: false
    }

    try {
      var path = '/apartment/'+apartmentID+'/deployment/publish';
      var response = await callAgencyAdmin(path);
      if(response.apartment) {  
        result.published = true;
        result.apartment = response.apartment;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  return {
    isListLoading,
    apartments,
    apartmentForm,
    selectedApartment,
    selectApartment,
    updateApartmentFormForUpdate,
    updateApartment,
    createApartment,
    removeApartment,
    uploadPicture,
    removePicture,
    uploadAttachement,
    removeAttachement,
    updateRanking,
    getPublishTargetApartment,
    publishApartment,
    isApartmentUpdateAllowed,
    isApartmentCreateAllowed,
    isApartmentRemoveAllowed,
    isApartmentPublishAllowed,
    updatePicturesRanking
  }
  
}