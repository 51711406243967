<template>

  <div class="cancel-booking">

    <hr/>

    <h3>{{ $t('hospitality.booking.cancel.title') }}</h3>
    <p>{{ $t('hospitality.booking.cancel.note') }}</p>
    <LocalizedText 
      v-bind:value.sync="form.cancel.reason" 
      :label="$t('hospitality.booking.cancel.reason')" 
      :languageCodes="availableLanguageCodes" 
      labelAsColumn 
      required
    />
    
    <button :disabled="buttonDisabled" class="btn btn-primary ladda-button cancel" data-style="zoom-in" type="button" @click="onCancelFormSubmit">{{$t('hospitality.booking.cancel.button')}}</button>
  
  </div>

</template>

<style>

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';

import * as Ladda from 'ladda';

import { useBookingAdmin } from '../../../../composables/useBookingAdmin';
import { useAgencyAdmin } from '../../../../composables/useAgencyAdmin';

export default defineComponent({
  props: {
      booking : {
        type: Object,
        required: true
      }
  },
  emits: ["booking-updated"],
  components: {
    LocalizedText
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { cancel } = useBookingAdmin({}, context);
    const { selectedAgency } = useAgencyAdmin({}, context);

    const { booking } = toRefs(props);

    const availableLanguageCodes = computed(() => {
      return ["fr", "en"];
    })

    const buttonDisabled = computed(() => {
      if(form.cancel.reason == undefined || Object.keys(form.cancel.reason).length == 0) {
        return "disabled";
      }
      return undefined;
    })
  
    const form = reactive({
      cancel : {
        reason : {}
      }
    })

    var laddaCancelSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var cancelButton:HTMLButtonElement|null = document.querySelector( '.cancel-booking button.cancel' );
      laddaCancelSubmit = Ladda.create(cancelButton!);
    });

    const onCancelFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaCancelSubmit!.start();

      cancel(booking.value._id, form.cancel.reason).then((result:any) => {
        if(result.cancelled) {
          context.emit("booking-updated", result.booking);
        }
        laddaCancelSubmit!.stop();
      })
    }

    return {
      booking,
      form,
      buttonDisabled,
      onCancelFormSubmit,
      availableLanguageCodes
    }
  }
})
</script>