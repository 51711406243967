<template>
    <table class="table cart">
        <thead>
            <tr>
                <th class="cart-product-name">{{ $t("hospitality.booking.rental.apartment") }}</th>
                <th class="cart-product-dates">{{ $t("hospitality.booking.rental.dates") }}</th>
                <th class="cart-product-subtotal">{{ $t("hospitality.booking.rental.total") }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(cartProduct, index) in products" :key="index + '-cart-product'" class="cart_item" ref="cartProduct">
                <td class="cart-product-name">
                    <div class="title">{{getLocalizedText(cartProduct.apartment.title)}}</div>
                </td>

                <td class="cart-product-dates">
                    <div>{{ $t("hospitality.booking.rental.check-in") }} <b> {{ formatDate(cartProduct.startDate) }}</b></div>
                    <div> {{ $t("hospitality.booking.rental.check-out") }} <b> {{ formatDate(cartProduct.endDate) }}</b></div>
                    <div>{{ $t("hospitality.booking.rental.number-of-nights") }} <b>  {{ getNumberOfNights(cartProduct) }} </b></div>
                </td>

                <td class="cart-product-subtotal">
                    <span class="amount">{{ formatPriceAmount(cartProduct.amount) }}</span>
                </td>
            </tr>

        </tbody>
    </table>
</template>

<style>

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDay, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';
import { diff } from '@igotweb-node-api-utils/date';


export default defineComponent({
    props: {
        products: {
          type: Array as PropType<any[]>,
          default: () => { return [] }
        }
    },
    components: {
        
    },
    setup(props, context) {
        const app = getApp();
        const store = useStore();

        const { products } = toRefs(props);

        const currentLanguageCode = store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

        const formatDate = (date:Date) => {
            return formatDay(date, currentLanguageCode, {type: FormatDateType.LONG});
        }

        const getNumberOfNights = (cartProduct:any) => {
            return diff(cartProduct.startDate,cartProduct.endDate).days;
        }

        return {
            formatDate,
            getNumberOfNights,
            products
        }

    }
})

</script>