<template>
    <div>
        <h3>{{ $t('hospitality.booking.info.cart') }}</h3>
        <Rentals :products="booking.products"></Rentals>
    </div>
</template>

<style>

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDay, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';
import { diff } from '@igotweb-node-api-utils/date';
import Rentals from './Rentals.vue';

export default defineComponent({
    props: {
        booking: {
          type: Object as PropType<any>,
          required: true
        }
    },
    components: {
        Rentals
    },
    setup(props, context) {
        const app = getApp();
        const store = useStore();

        const { booking } = toRefs(props);

        return {
            booking
        }

    }
})

</script>